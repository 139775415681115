<script setup lang="ts">
import { clamp } from '#core/utils/number'

const props = withDefaults(
  defineProps<{
    percent: number | string
    lighter?: boolean
  }>(),
  {
    percent: 0,
  },
)

const percent = computed(() => clamp(Number(props.percent), 0, 100))

const color = computed(() => {
  return percent.value === 100
    ? 'bg-forgd-green-500'
    : 'bg-forgd-primary-300'
})

const bg = computed(() => {
  return props.lighter
    ? 'bg-forgd-bgd-400'
    : 'bg-forgd-bgd-600'
})
</script>

<template>
  <div data-ui="UiProgressBar" v-bind="$attrs" class="rounded-full h-1.5" :class="bg">
    <div
      class="h-1.5 rounded-full"
      :class="color"
      :style="`width: ${percent}%`"
    />
  </div>
</template>
